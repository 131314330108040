.container {
    color: var(--text-color);
    margin-bottom: 50px;
}

.mapContainer {
    width: 90%;
    margin: auto;
    border-radius: 15px;

    min-height: 400px;
}



.main {
    display: flex;
    flex-direction: row;
    margin: auto;
}

.formContainer {
    border-right: 1px solid var(--header-border-color);
    display: flex;
    flex-direction: column;
    width: 50%;
}
.logoImg {
    width: 57px;
    height: 39px;
    display: block;
    margin-left: 45px;

}

.header {
    /*text-align: center;*/
    white-space: nowrap;

    text-align: left;
    width: 40%;
}

.address {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-self: flex-start;
    flex-direction: column;

}

.addressContainer {
    width: 50%;
    align-items: flex-start;
    color: var(--text-color);
    display: flex;

}
.addressHeader {
text-align: left;
    font-size: 16px;
    margin-top: 10px;
    display: inline-block;

}
.addressLinesContainer {
   /* align-self: center;*/
    width: 50%;
    display: block;
    margin: 0 auto 10px;
}

.addressLine, .addressLine1 {
    font-size: 18px;
    /*font-weight: 600;*/
    text-align: left;
    line-height: 5px;
    white-space: nowrap;
}

.labelStyle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;

}

.emailLink {
    color: var(--text-color);
}


.captcha {
    transform: scale(1);
    transform-origin: 50% 50%;

}
.inputContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
    position: relative;
}


.errorMessageField {
    position: absolute;
    bottom: -39%;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    font-size: 12px;
}

.errorMessage {
    position: absolute;
    bottom: -20%;
    left: 50%;
    transform: translateX(-50%);
    color: red;
    font-size: 12px;
}

.inputField {
    border: 1px solid var(--header-border-color);
    margin-bottom: 5px;
    border-radius: 10px;
    padding: 10px 10px;
    background-color: var(--header-background-color-finish);
    position: relative;
}


.inputContainer:before {
    content: "";
    background-size: contain;
    background-repeat: no-repeat;
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 25%;
    transform: translateY(25%);
    left: -15%;
}

.nameField:before {
    background-image: url("../../assets/icons/avatar.webp");
}
.skypeField:before {background-image: url("../../assets/icons/skype.webp");}
.phoneField:before {background-image: url("../../assets/icons/whatsUp.webp");
transform: scale(110%);
}
.mailField:before {background-image: url("../../assets/icons/mail.webp");
transform: translateY(40%);
}
.messageField:before{background-image: url("../../assets/icons/message.webp");
top: 10%;
}

.inputField:focus-within {
    background-color: #ffffff;

    outline: none;
}

.form {
    display: flex;
    flex-direction: column;
    max-width: 304px;
    margin: 0 auto;
    justify-content: space-between;
    flex-grow: 1;

}

.button {
    height: 2.5em;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    cursor: pointer;
    font-size: 18px;
    color: white;
    border: none;
    background-color: #222629;
    transition: all .3s ease-in-out;
    margin-top: -40px;
    padding: 15px 0 !important;
    margin-bottom: 15px;

}

.button:hover {
    color: #ebe7e7;
    background-color: var(--send-button-hover);
}

.button:disabled, .button:disabled:active {
    color: var(--header-text-color);
    background-color: darkgray;
    cursor: auto;
}

.button:active {
    color: #ffffff;
    background-color: #b4b4b4;
}



@media screen and (max-width: 992px){
    .main {
        width: 90%;
    }

    .form {
        max-width: 80%;
    }

    .addressLinesContainer {
        width: 100%;
        padding-left: 15px;
    }

}

@media screen and (max-width: 860px) {
    .captcha {
        transform: scale(0.8);
        transform-origin: 50% 50%;
        margin-bottom: 0;
    }
}



/*@media screen and (max-width: 768px) {

    .addressLinesContainer {
        width: 100%;
        padding-left: 15px;
    }

    .form {
        max-width: 80%;
    }

    .addressLine {
        font-size: 16px;
    }
    .inputContainer:before {
        left: -18%;
    }
}*/

@media screen and (max-width: 768px){
    .logoImg {
        /*height: 35px;
        width: 50px;*/
        margin: auto;
    }

    .mapContainer {
        width: 100%;
    }

.header {
    width: 100%;
    text-align: center;
}


.main {

    flex-direction: column;
}
    .formContainer, .address {
        width: 100%;
    }
    .formContainer {
        border: none;

    }

    .smallBlock {
       width: 25%;
        margin: auto;
        text-align: left;
    }

    .addressLinesContainer, .addressHeader, .addressLine {
        padding: 0;
        text-align: center;

    }

    .captcha {
        transform: scale(1);
        transform-origin: 50% 50%;
    margin-bottom: 15px;
        align-self: center;
    }

    .button {
        margin-top: -25px;
        margin-bottom: 40px;
    }

}



@media screen and (max-width: 480px) {
    .smallBlock {
        width: 60%;
        margin: auto;
    }
    .addressLine1 {
        font-size: 18px;
    }

    .captcha {
        transform: scale(0.9);
        transform-origin: 50% 50%;

        align-self: center;
    }
    .formContainer {
        text-align: center;
        justify-content: center;
        align-items: center;
    }

    .inputContainer:before {
        left: -15%
     /*display: none;*/
    }
    .button {
        margin-top: -25px;
    }


    .addressHeader {
        font-size: 22px;
    }
    .addressLine {
        font-size: 18px;
    }

    .button {
        margin-top: -25px;
    }

}

@media screen and (max-width: 380px) {
    .smallBlock {
        width: 70%;
    }

}


@media screen and (max-width: 330px) {
    .smallBlock {
        width: 90%;
        margin: auto;
    }
    .smallBlock > p {

        text-align: left;

    }

    .captcha {
        transform: scale(0.8);
        transform-origin: 50% 50%;
        align-self: center;
    }
    .form {
        max-width: 80%;
    }

    .inputContainer:before {
        display: none;

    }
}

