.header {
    z-index: 2;
    margin: auto;
    /*display: flex;
    justify-content: space-around;*/
    /*max-width: 80%;*/
    padding: 10px 10px;
    border-radius: 25px;
    /*position: absolute;*/
    opacity: 0.9;
    background: linear-gradient(to bottom, var(--header-background-color-start), var(--header-background-color-finish));
    box-shadow: 0 7px 30px rgba(0,0, 0, 0.7);
    top: 0;
    color: var(--header-text-color);
    position: relative;

}

.desktopMenu {
    display: block;
    position: relative;
}

/*----------mobile section-------*/
.mobileMenu {
    display: none;
}
/*----------mobile section---------*/

.logo {
    margin: 0;
    /*flex: 1;*/
    text-align: left;
    position: absolute;
    bottom: 50%;
    transform: translateY(50%);
    width: 25%;
    transition: opacity 0.3s ease-in-out;
}

.logoWrap {
    height: 100%;
    left: 1%;
    width: 20%;
    position: absolute;
    top: 0;
    cursor: pointer;
    text-decoration: none;
    color: currentColor;
}

.logoText {
    left: 25%;
    position: absolute;
    margin: 0 auto;
    bottom: 50%;
    transform: translateY(50%);
    transition: opacity 0.3s ease-in-out;
}

.logoWrap:hover .logo, .logoWrap:hover .logoText  {
  opacity: 50%;
}

.logoImg {
    width: 80%;
    height: 10%;
}

.navWrap {
    width: 60%;
    margin: auto;
}
.navList {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0;
    flex: 0;
}

.navDivider {
    border-right: 2px solid var(--header-border-color);
}

.navItem {
    cursor: pointer;
    padding: 5px 10px;
    border-radius: 10px;
    transition: all .3s ease-in-out;
    white-space: nowrap;
}

.navItem:hover {
    color: white;
    background-color: #414d51;
}


.burger {
    /* left: 20px;*/
    width: 36px;
    height: 28px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-self: center;
    border: none;
    padding: 0;
    position: relative;
    transition: visibility .3s ease-in-out, opacity .3s ease-in-out, transform 3s ease-in-out;
    cursor: pointer;
    box-shadow: none
;

}

.burgerSpan {
    padding: 0;
    background-color:var(--text-color);
    height: 4px;
    width: 100%;
    transition: transform .3s ease-in-out, width .3s ease-in-out, opacity .2s ease-in-out;

}

.open.burgerSpan:first-child {

    transform: rotate(-45deg) translate(-9px, 9px);
}

.open .burgerSpan:nth-child(2) {
    opacity: 0;
}

.open .burgerSpan:last-child {
    transform: rotate(45deg) translate(-7px, -8px);
}

.burgerBtn {
    position: relative;
    z-index: 100;
}


@media screen and (max-width: 1420px) {
    .logoText {
        font-size: 14px;
    }

}


@media screen and (max-width: 1320px) {
    .navWrap {
        width: 40%;
        font-size: 14px;

    }

    .logoImg {
        /*width: 100%;*/
    }

    .logo {
        /*left: 2%*/
    }
}

@media screen and (max-width: 1200px) {
    .navWrap {
        /*width: 95%;/*/
        font-size: 12px;
    }
    .logoText {
        font-size: 12px;
    }

}

@media screen and (max-width: 992px) {
    .mobileMenu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 20px;
        align-items: center;
    }

    .mobileLogoWrap {
        display: flex;
        width: 45%;
        text-decoration: none;
        color: currentColor;
    }

    .mobileLogoText {
        font-size: 16px;

    }

    .mobileLogo {
        width: 25%;

        margin: auto;
    }
    .mobileLogoText {
        white-space: nowrap;

    }
    .mobileLogoImg {
        height: 5%;
        width: 80%;
    }
    .desktopMenu {
        display: none;
    }

    .navWrap {
        padding: 0 25px;
        width: 100%;
        height: 50vh;
        display: flex;
    }
    .navList {
padding: 0;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
    }

    .navItem:not(:last-child) {
        margin-bottom: 15px;
        border-bottom: 2px solid white;
    }

    .navItem {
        padding-bottom: 10px;
        text-align: center;
        border-radius: 0;
    }



    .navDivider {
        display: none;
    }
}

@media screen and (max-width: 768px) {
    .mobileLogoWrap {
        width: 43%;
    }

    .mobileLogoText {
        font-size: 16px;

    }
}

@media screen and (max-width: 576px) {
    .mobileLogoWrap {
        width: 70%;

    }
    .mobileLogoText {
        font-size: 14px;

    }


}

@media screen and (max-width: 420px) {
    .mobileLogoWrap {
        width: 70%;
    }

    .mobileLogoText {
        width: 70%;


    }
    .mobileLogo {
        width: 30%;
    }
}


@media screen and (max-width: 360px) {
    .mobileLogoWrap {
        width: 90%;
    }
    .mobileLogo {
        width: 20%;
    }

    .mobileLogoText {
        width: 70%;
        font-size: 12px;
    }
}

@media screen and (max-width: 330px) {
    .mobileLogoWrap {
        width: 100%;
    }
    .mobileLogoText {
        display: none;
    }

    .mobileLogo {
        width: 40%;
    }
}
