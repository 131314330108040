.container {
    width: 80%;
    margin: 100px auto;
    text-align: left;
}

.header {
    text-align: center;
}

.address {
    width: 100%;
}

.orderedListItem {
    font-weight: 600;
}

ul {
    list-style-type: disc;
}

.link {
    color: var(--text-color);
    text-decoration: none;
    position: relative;
    display: inline-block;
}


.link::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: #007aff;
    transform-origin: bottom center;
    transition: transform .3s ease-in-out;
}


.link:hover::after {
    transform: scaleX(1);
    transform-origin: bottom center;
}


@media screen and (max-width: 420px) {
    .container {
        width: 100%;
    }
    ol {
        padding-left: 20px;
    }
}
