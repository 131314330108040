.container {
    background-color: black;
    width: 100%;
    position: relative;
}
.content {
    max-width: 1440px;
    margin: auto;
    padding: 10px 20px;
    color: var(--text-footer);
    display: flex;
    justify-content: space-between;
}
.logoImg {
    width: 6em;
    height: 4em;
    margin-right: 20px;
/*    height: 50px;*/
    /*aspect-ratio: 1/1;*/
}
.logoContainer {
display: flex;
    cursor: pointer;
align-items: center;
}

.logoText {
    display: block;
 align-self: center;
}

.logoTextMob, .logoTextYear {
    display: none;
}


.socialLink:not(:last-child) {

    margin-right: 10px;
}
.socialImg {
    width: 3em;
    opacity: 1;
    transition: opacity .3s ease-in-out;
}
.socialLink:hover .socialImg {
    opacity: .8;
}
.privacy {
    margin: auto;
}
.privacyLink {
    text-decoration: none;
    color: var(--text-footer)
}

.socialContainer {
    /*flex-direction: row;*/
/*    flex: 0.5;*/
    display: flex;
    align-self: center;
}

.chatContainer {
    width: 300px;
    position: absolute;
    right: 10%;
    transform: translateY(-100%);
    top: 0;
    margin: auto;
}
.chatWrap {
    position: relative;
}
.chatBtn {
    /*position: absolute;*/
    background-color: var(--chat-button-color);
    color: #fff;
    font-size: 18px;
    /*left: 65%;**/
    /*transform: translateY(-100%);*/
    border: none;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    padding: 5px 0;
    min-width: 300px;
    cursor: pointer;
}

.chatBlock {
    height: 0;
    transition: height 0.2s linear;
    border: 1px solid var(--chat-button-color);
    margin: auto;
    }

.active {
    height: 600px;
}


@media screen and (max-width: 1024px) {
    .chatContainer {
        right: 10%;
        width: 300px;
    }


    .chatBtn {
        max-width: 300px;
    }

    .content {
        flex-direction: column-reverse;
        justify-content: space-between;
        align-items: stretch;
    }
    .socialContainer {
        margin-bottom: 10px;

    }
    .logoContainer {
        align-self:
                center;
    }
.logoTextMob, .logoTextYear {
    display: none;
}

.logoText {
    display: block;
}

}

@media screen and (max-width: 480px){

    .privacy {
        margin: 20px auto;
    }
    .chatContainer {
        left: 50%;
        transform:translate(-50%, -100%);
        width: 300px;
    }


    .chatBtn {
        max-width: 300px;
    }
.active {
    height: 450px;
}
.socialImg {
    width: 35px;
}

    .content {
        padding-bottom: 20px;
    }

    .logoImg {
        width: 55px;
        height: 40px;
        margin-right: 20px;
    }

    .logoText {
        font-size: 16px;
        display: none;
    }

    .logoTextMob{
        display: block;
        font-size: 14px;
        margin: 0;
        white-space: nowrap;

    }

    .logoTextYear {
font-size: 12px;
        display: block;

        padding: 0;
        margin: 0;
        text-align: center;
    }
}
@media screen and (max-width: 320px) {
    .chatContainer {
        /*right: 35%;**/
       /* left: 50%;
        transform:translate(-50%, -100%);*/
        width: 80%;
    }
.privacyLink {
    font-size: 10px;
}
.privacy {
    margin: 10px auto;
}
    .chatBlock {
        margin: auto;
        width: 80%;
    }


    .chatWrap {
        text-align: center;
    }


    .chatBtn {
        margin: auto;
        max-width: 150px;
        min-width: 80%;
        width: 80%;
    }

    .logoTextYear, .logoTextMob {
        font-size: 10px;
    }
.socialImg {
    width: 25px;
}
}
