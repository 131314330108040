.container {
    color: var(--text-color);
    margin-bottom: 50px;
}

.mainContent {
    float: left;
    width: 30%;
}
.thinkingImg {
    width: 80%;
    padding: 0 10px 20px 0;
}

.mainText {
    text-align: left;
    padding-right: 10px;
/*    margin-bottom: 50px;*/
    line-height: 19px;
    margin: auto auto 50px;
    display: flex;
    flex-direction: column;


}

.cite {
    /*width: 40%;*/
    padding: 20px 0;
    margin: auto ;
    text-align: center;
    font-size: 20px;
}

.block {
    margin: 20px 0 10px 0;
    font-size: 5px;
    quotes: "\201C""\201D""\2018""\2019";
    display: inline;
    text-align: center;

}
.block p {
    font-size: 20px;
    display: inline;
    text-align: center;
    font-family: Baskerville, sans-serif;
    font-weight: 600;

}

.block p::before {
    content: "\201D";
    font-size: 30px;
}

.block p::after {
    content: "\201C";
    font-size: 30px;

}

.caption {
    font-family: Baskerville, sans-serif;
    text-align: center;
}
@media screen and (max-width: 768px){
    .thinkingImg {
        padding: 0;
    }
    .mainText {
        display: block;
    }

}
