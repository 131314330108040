.App {
  text-align: center;
  /*position: relative;*/
  max-width: 1440px;
  margin: auto;
  padding: 0 20px;
  overflow: hidden;
}

.App > div {
/*width: 100vw;*/
  /*min-height: 100vh;*/
  /*margin-top: 100px;*/
}

.mainMenu {
  z-index: 1;
  position: fixed;
  width: 75%;
  top: 20px;
  max-width: 1440px;
  left: 50%;
  transform: translate(-50%, 0);
}

.language {
  position: fixed;
  top: -1px;
  right: 0;



}

@media screen and (max-width: 1320px) {
  .mainMenu {
    width: 80%;
  }
}

@media screen and (max-width: 1024px) {
  .mainMenu {
    width: 85%;
  }
}

@media screen and (max-width: 992px) {
  .language {
    display: none;
  }
}

@media screen and (max-width: 576px){

  .App {
    padding: 0 10px;
  }

}
