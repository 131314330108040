.container {
    color: var(--text-color);
    margin-bottom: 50px;
    /*padding-bottom: 50px;*/
}

.description {
display: flex;
}

.descriptionText {
text-align: left;
    padding-right: 10px;
    margin-bottom: 150px;
    width: 50%;
}

.descriptionText:first-child {
    border-right: 1px solid var(--header-border-color);
}

.descriptionText:not(:first-child) {
                      padding-left: 10px;
}

/*----------*/

.circleContainer {
    margin: auto auto 80px;
    width: 30%;
    aspect-ratio: 1/1;
    /*max-width: var(--main-container);*/
    font-size:0;
    border-radius:50%;
    /*background-color: var(--color-main-bubble);*/
    position: relative;
}

.deg1, .deg2, .deg3, .deg4,  .deg6, .deg7,  .deg9 {
    display:inline-block;
    width:calc(100% / 3);
    height:calc(100% / 3);
    text-align:center;
    border-radius:50%;
    font-size: 10px;
    position: absolute;

}


.iconDescription {
    font-size: clamp(12px, 2vw, 18px);
    white-space: nowrap;
    font-weight: 400;
    margin: 0;
    text-align: left;
    color: white;
    padding: 10px 25px;
    background-color: var(--text-bubbles);
    border-radius: 10px;
    position: relative;
    transition: all .1s linear;
    opacity: 0;
}

/*.iconDescription:hover {

    box-shadow:   0 7px 30px var(--text-bubbles);
}*/




.iconDescriptionLeft:before {
    content: url("../../assets/icons/tail.png");
    display: flex;
    position: absolute;
    bottom: -45%;
    right: -5%;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

.iconDescriptionRight:before {
    content: url("../../assets/icons/tail.png");
    display: flex;
    position: absolute;
    bottom: -45%;
    left: -5%;
}

.icon {
    border-radius: 50%;
    background-color: var(--bubbles-color);
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    padding:  clamp(40px, 3vw, 65px);
    position: relative;
}


.iconImg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:clamp(40px, 4vw, 75px);
    position: absolute;
}

.deg5{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.centerIcon {
    /*padding:  clamp(30px, 10vw, 180px);-*/
    position: relative;
}

.centerIconImg {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:clamp(120px, 15vw, 250px);
    position: absolute;
}


.iconWrap{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.icon1Wrap, .icon3Wrap,.icon4Wrap,.icon6Wrap,.icon7Wrap, .icon9Wrap {
    flex-direction: row;
}

.deg2 {
    top: -23%;
    left: 50%;
    transform: translateX(-50%);
}

.deg2 .iconDescription {
    transform: translate(78%, 50%);
}

.deg1 {
    top: 0;
    right: 100%;
}

.deg1 .iconDescription {
    transform: translate(-5%, -100%);
}

.deg3 {
    top: 0;
    left: 100%;
}

.deg3 .iconDescription {
    transform: translate(5%, -100%);
}

.deg4  {
    top: 50%;
    right: 85%;
    transform:translate(-70%, -50%);
}

.deg4 .iconDescription {
    transform: translate(-5%, -100%);
}


.deg6  {
    top: 50%;
    left: 85%;
    transform:translate(70%, -50%);
}

.deg6 .iconDescription {
    transform: translate(5%, -100%);
}

.deg7 {
    top: 75%;
    right: 80%;
}

.deg7 .iconDescription {
    transform: translate(-5%, -100%);
}

.deg9 {
    top: 75%;
    left: 80%;
}
.deg9 .iconDescription {
    transform: translate(5%, -100%);
}
.footerCont {
    min-height: 280px;
}
.portfolioFooter {
    float: left;
    width: 30%;

}
.crossPlatformImg{
    width: 100%;
    padding: 0 10px 20px 0;
}

.portfolioFooterText {
    text-align: left;
    padding-right: 10px;
margin: auto;
    line-height: 19px;
    display: flex;
    flex-direction: column;
}

@media screen and (max-width: 1200px){

    .descriptionText {
        margin-bottom: 50px;
    }

    .deg2 {
        top: -20%;
    }

    .deg2 .iconDescription {
        transform: translate(65%, -15%);
    }
    .deg1 {
        top: 10%;
        right: 100%;
    }

    .deg3 {
        top: 10%;
        left: 95%;
    }
    .deg3 .iconDescription {
    transform: translate(5%, -100%);
    }

    .deg4 {right: 80%}
    .deg6{left: 80%}
    .deg7{right: 80%;
        top: 70%

    }
    .deg9{ top: 72%;
        left: 80%;
    }

    .centerIconImg {
        width: 140px;
    }
    .icon {
        padding: 35px;
    }

    .iconImg {
        width: 35px;
    }

    .iconDescription {
        font-size: 16px;
    }

}

@media screen and (max-width: 1024px) {
    .descriptionText {
        margin-bottom: 100px;
    }
    .iconDescription:before {
        display: none;
    }
    .icon {
        padding: 30px;
    }

    .iconImg {
        width: 35px;
    }


    .deg2 {
        top: -20%
    }

    .deg2 .iconDescription {
        transform: translate(0%, -20%);
    }

    .deg3 .iconDescription {
        transform: translate(5%, -90%);
    }

    .circleContainer {
        margin: auto auto 40px;
    }
}

@media screen and  (max-width: 992px){
    .centerIconImg {
        width: 110px;
    }

    .deg2 {
        top: -20%;
    }

    .deg1 {right: 110%}
    .deg3 { left: 110%}
    .deg1 .iconDescription {
        transform: translate(-10%, 0%);
    }

    .deg3 .iconDescription {
        transform: translate(10%, 0%);
    }

    .deg4 {right: 100%}
    .deg6{left: 100%}

    .deg4 .iconDescription {
        transform: translate(-10%, 0%);
    }

    .deg6 .iconDescription {
        transform: translate(10%, 0%);
    }

    .deg7 {right: 95%}
    .deg9 {left: 95%}

    .deg7 .iconDescription {
        transform: translate(-10%, 0);
    }

    .deg9 .iconDescription {
        transform: translate(10%, 0);
    }


}

@media screen and (max-width: 768px){
    .crossPlatformImg {
        padding: 0 10px 0 0;
    }
    .portfolioFooter {
        float: left;
    }

    .portfolioFooterText {
        display: block;
    }
    .description {
        flex-direction: column;
    }
    .descriptionText {
        width: 100%;
        margin-bottom: 0;

    }

    .descriptionText:last-child {
        padding-left: 0;
        margin-bottom: 80px;
    }

    .descriptionText:first-child {
        border: none;
    }
    .iconDescription:before {
        display: none;
    }

    .deg1{
        top: 5%;
        right: 120%;
    }

    .deg3 {
        top:5%;
        left: 120%;
    }

    .deg1 .iconDescription {
        transform: translate(-10%, 0%);
    }

    .deg3 .iconDescription {
        transform: translate(10%, 0%);
    }

    .deg2 {
        top: -30%
    }

    .deg2 .iconDescription {
        transform: translate(0%, -20%);
    }

    .deg4 .iconDescription {
        transform: translate(-10%, 0%);
    }

    .deg6 .iconDescription {
        transform: translate(10%, 0%);
    }

    .deg7 .iconDescription {
        transform: translate(-10%, 0%);
    }

    .deg9 .iconDescription {
        transform: translate(10%, 0%);
    }

}

@media screen and (max-width: 650px){
    .iconDescription {
        font-size: 12px;
    }

    .centerIconImg {
        width: 90px;
    }

    .icon {
        padding: 25px;
    }

    .iconImg {
        width: 32px;
    }

}


@media screen and (max-width: 576px) {

    .descriptionText {

    }
.iconDescription {
    white-space: normal;
    padding: 5px 10px;
}

.deg2 .iconDescription {
    white-space: nowrap;
}

    .centerIconImg {
        width: 90px;
        transform: translate(-50%, -60%);
    }

    .icon {
        padding:  25px;
    }


    .iconImg {
        width: 30px;
    }


.deg2 {
        top: -50%;
        transform: translateX(-50%);
    }

    .deg1 {
        top: -10%;
        right: 110%;
    }

    .deg3 {
        top: -10%;
        left: 120%;
    }

    .deg4  {
        top: 50%;
        right: 110%;
        transform:translate(-50%, -50%);
    }

    .deg6  {
        top: 50%;
        left: 110%;
        transform:translate(50%, -50%);
    }

    .deg7 {
        top: 75%;
        right: 100%;
    }



    .deg9 {
        top: 75%;
        left: 100%;
    }





    .topDescription {
        font-size: 11px;
    }
    .iconDescription {
        font-size: 10px;
        font-weight: 400;
    }



}

@media screen and (max-width: 480px) {
    .descriptionText {

    }
    .centerIconImg {
        width: 60px;
        transform: translate(-50%, -55%);
    }
.deg2 {
    top: -40%;
}
    .deg1, .deg4  {
        right: 120%;
    }

    .icon {
        padding: 15px;
    }

    .iconImg {
        width: 20px;
    }

    .deg9 {
        left: 95%
    }
    .deg7 {
        right: 110%;
    }


}

@media screen  and (max-width: 320px) {
    .centerIconImg {
        width: 55px;
        transform: translate(-50%, -55%);
    }

    .icon {
        padding:  12px;
    }


    .iconImg {
        width: 15px;
    }


    .deg2 {
        top: -60%;
        transform: translateX(-50%);
    }

    .deg1 {
        top: -10%;
        right: 120%;
    }

    .deg3 {
        top: -10%;
        left: 120%;
    }

    .deg4  {
        top: 50%;
        right: 120%;
        transform:translate(-50%, -50%);
    }

    .deg6  {
        top: 50%;
        left: 110%;
        transform:translate(50%, -50%);
    }

    .deg7 {
        top: 80%;
        right: 110%;
    }


    .deg9 {
        top: 75%;
        left: 100%;
    }



    .iconDescription {
        padding: 5px;
        font-size: 10px;
        font-weight: 400;
    }

}



