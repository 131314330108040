.arrow {
    display: block;
    color: #fff;
}

.but {
    padding: 0 5px 10px 10px;
    border-bottom-left-radius: 15px;
    background-color: #414d51;

}

@media screen and (max-width: 1024px) {
    .arrow {
        display: none;
    }
}

@media screen and (max-width: 992px) {
    .but {
        border-radius: 0;
        background-color: transparent;
        padding: 0;
        transform: none;
        align-self: center;

    }
}
