@import '~@csstools/normalize.css';
*, *::after, *::before {
  box-sizing: border-box;
}

@font-face {
  font-family: "Baskerville";
  font-style: normal;
  font-weight: 400;
  src: local(''),
  url("../public/fonts/Baskerville-Old-Face-V2.woff2") format("woff2"),
  url("../public/fonts/BaskOldFace.woff") format("woff");
  font-display: swap;
}


:root {

  --text-color: #353434;
  --header-text-color: #6c6d6f;
  --header-border-color: #c6caca;
  --header-background-color-start: #f2f2f2;
  --header-background-color-finish: #dadddd;
  --main-background-color: #fbfbfb;
  --bubbles-color: #212629;
  --text-bubbles: #6e609e;
  --text-footer: #cfcfce;
  --send-button-hover: #ef6324;
  --chat-button-color: #ef6324;
}


.react-tel-input .selected-flag:hover, .react-tel-input .selected-flag:focus {
  background-color: transparent !important;
  cursor: auto;
}


html {
  background-color: var(--main-background-color);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--main-background-color);
  transition: background-color .3s ease-in-out;
  position: relative;
}


.MuiDrawer-paper {
  color: white !important;
  z-index: -1 !important;
  transition: background-color .3s ease-in-out;
  background-color: rgba(0,0,0, 0.7) !important;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.cookie__buttons-wrapper {
  margin: auto;
}

.cookie__accept-button, .cookie__reject-button {
  border-radius: 25px !important;
}

@media screen and (max-width: 480px) {
  .cookie__accept-button, .cookie__reject-button, .cookie__container {
    font-size: 12px;
  }
}

@media screen and (max-width: 320px) {
  .cookie__content {
    margin: 15px 0 !important;
  }
}

@media screen and (max-width: 270px) {
  .cookie__content {
    margin: 15px 0 !important;
    flex:  1 !important;

  }

}






/*-----------*/

