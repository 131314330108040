.container {
    /*margin-top: 100px;*/
    width: 100%;
    margin: 120px auto 50px;
    /*height: 500px;*/
    padding-right: 25px;
    position: relative;
    z-index: 0;
    /*padding-bottom: 50px;*/
    perspective: 500px;
}

.sliderSection {
    display: none;
    width: 80%;
    margin: auto;

}

.card1 {
    background-image: url("../../assets/images/4.webp");

}

.card2 {
    background-image: url("../../assets/images/3.webp");
}

.card3 {
    background-image: url("../../assets/images/2.webp");
}

.card4 {
    background-image: url("../../assets/images/1.webp");
}

/*---*/

.desktopSection {
    display: flex;
    flex-direction: row;
    margin: auto auto 50px;
    width: 70%;
    /*height: 320px;*/
    position: relative;
    cursor: pointer;
    perspective: 500px;

}



.swiper {
    display: none;
    z-index: 0;
    /*width: 320px;*/

}

.swiperSlide {
    display: flex;
    /*display: inline-block;*/
    width: 25%;
    min-height: 500px;

}


.swiperCard {
    background-position-y: 118%;
    background-size: 122%;
    background-position-x: 50%;
    position: relative;
    border-top: 40px solid transparent;
    border-left: 22px solid transparent;
    border-right: 22px solid transparent;
    border-bottom: 20px solid transparent;
    border-image-source: url("../../assets/images/phone_frame.webp");
    border-image-slice: 8% 9% 4% 9%;
    background-color: rgb(53, 52, 52);
    border-image-outset: 15px 15px 15px 15px;
    background-repeat: no-repeat;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    min-height: 460px;
    width: 100%;

}

.textContainer {
    /*margin-right: -10px;
    margin-left: -10px;*/
    width: 100%;

}

.description {
    font-size: 16px;
    text-align: left;
    margin-bottom: 220px;
    width: 100%;


}

.header {
    line-height: 25px;
    font-size: 18px;
    text-align: center;
    margin: 0 0 20px 0;
    white-space: nowrap;

}

.subHeader {
    margin: 0 0 10px 0;
    font-size: 16px;

}


.disabled {
    cursor: not-allowed !important;

    opacity: .5;
}



.disabled > .arrow {
    cursor: not-allowed;
}

.navButton {
    position: absolute;
    top:50%;
    transform: translateY(-50%);
    z-index: 15;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: opacity .3s ease-in-out;
}

.upperDiv {
    border-top: 1px solid white;
    width: 100%;
    margin: auto;
}
.lowerDiv {
    position: absolute;
    margin: 0 auto;
    bottom: 50%;
    width: 100%;
    border-bottom: 1px solid white;

}

.nextButton {
    right: 0;
    z-index: 15;
}

.buttonLayer {

}
.arrow {
    cursor: pointer;
    /*position: absolute;*/
    z-index: 15;
}

.disabled .arrow:hover .arrowImg {
    opacity: 0.8;
}

.arrow:hover .arrowImg {
    opacity: 1;
}

.arrowImg {
    width: 45px;
    opacity: 0.8;
    transition: opacity .3s ease-in-out;
}

.leftArrow {
    transform: rotate(180deg);
    z-index: 15;
}

.rightArrow {
    z-index: 15;
}

@media screen and (max-width: 1400px) {
    .lowerDiv {
     /*   bottom: 45%;-*/
    }
}



@media screen and (max-width: 1300px) {
    .swiperCard {
        background-position-y: 107%;
        background-size: 132%;
        background-position-x: 50%;
    }

    .lowerDiv {
        bottom: 55%;
    }

    .textContainer {
        /*margin-right: 0;
        margin-left: -20px;*/
        width: 100%;
    }

    .description {
        width: 110%;
        font-size: 14px;
        margin-left: -5px;
    }

    .header {
        font-size: 15px;

    }

    .card1 .header{

        margin-left: -1px;
    }



}

@media screen and (max-width: 1100px) {

    .description {
    font-size: 14px;
        margin-left: -5px;
    }



    .swiperCard {
        background-position-y: 108%;
        background-size: 142%;
        background-position-x: 50%;
        width: 100%;
    }
    .lowerDiv {

        bottom: 45%;
    }

    .header {
        font-size: 14px;

    }

    .card1 .header{

        margin-left: -10px;
    }

}



@media screen and (max-width: 992px) {
    .swiper {
        display: block;
        z-index: 0;
        width: 60%;
        margin: auto;

    }



    .swiperSlide {
         width: 80%;
        overflow: visible;
        padding: 20px;
        min-height: 600px;
    }

    .sliderSection {
        display: block;
        width: 90%;
        padding-bottom: 10px;
    }


    .swiperCard {

        background-size: 120%;
        background-position-y: 118%;
        min-height: 720px;
    }

    .textContainer {
        margin-right: 0;
        margin-left: 0;

    }

    .description {
        font-size: 16px;
        text-align: left;
        margin-left: 0;
        width: 95%;

    }

    .header {
        font-size: 20px;
    }


    .upperDiv {
        border-top: 1px solid white;
        width: 100%;
        margin: auto;
    }
    .lowerDiv {

        display: none;

    }


    .container {
        padding: 0;
    }
    .arrow, .navButton {
        display: none;
    }

    .desktopSection {
        display: none;
    }


}

@media screen and (max-width: 768px) {
    .swiper {
        width: 60%;
    }

    .swiperCard {
        min-height: 520px;
    }
    .lowerDiv {
        display: none;
        bottom: 65%;
    }
}



@media screen and (max-width: 576px) {

    .arrow {
        display: none;
    }

    .textContainer {
     /*   margin-top: -20px;*/
    }

    .swiperSlide {
        display: inline-block;
        width: 100%;
        overflow: visible;
        padding: 15px;
    }

    .sliderSection {
        width: 90%;
    }
    .swiperCard {

        background-size: 120%;
        background-position-y: 108%;
        width: 100%;
        margin: auto;
        min-height: 580px;


    }

    .description {
        font-size: 18px;
    }


    .desktopSection {
        display: none;
    }
    .sliderSection {
        display: block;
    }
    .lowerDiv {
    display: none;
        bottom: 55%;
    }
}

@media screen and (max-width: 480px){
    .lowerDiv {
        bottom: 55%;
        display: none;
    }


    .swiper {
        width: 85%;
    }

     .swiperCard {

         background-position-y: 135%;




        min-height: 520px;
    }

    .description {
        /*margin-left: -10px;*/
        /*margin-right: -10px;*/
        margin-top: 25px;

        /*margin-bottom: 150px;*/
    }

    .textContainer {
        margin-top: 0;
    }

    .header {
        font-size: 20px;
    }
    .container {
        margin-bottom: 30px;
    }

}

@media screen and (max-width: 420px) {
    .swiperCard {
        background-position-y: 120%;
    }

}

@media screen and (max-width: 380px) {

    .swiperCard {

        background-position-y: 110%;

        min-height: 520px;
    }

    .header {
        /*white-space: normal;*/
        font-size: 14px;

    }

    .card1 .header {
        margin-left: -15px;
    }

    .description {
        margin-left: 0px;
        font-size: 15px;
        width: 100%;
    }
}

@media screen and (max-width: 280px) {
    .description {
        font-size: 12px;
    }

    .swiperCard {
        min-height: 480px;
        background-size: 150%;
        background-position-y: 110%;
    }


}
